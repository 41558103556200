var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Orders")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"search":_vm.search,"custom-filter":_vm.filterItems,"loading":_vm.isLoading,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{attrs:{"label":"Only show pending orders"},model:{value:(_vm.isPendingOnly),callback:function ($$v) {_vm.isPendingOnly=$$v},expression:"isPendingOnly"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm.formatDate(item.sale.created_at)))]),_c('br'),_vm._v(" "+_vm._s(_vm.formatTime(item.sale.created_at))+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'shop-product', params: { productId: item.product.id }}}},[_vm._v(" "+_vm._s(item.product.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var order = ref.item;
return [(order.completed_at === null)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openDialog(order, 'complete')}}},[_vm._v(" Completed ")]):_vm._e(),(order.refund === null)?_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openDialog(order, 'refund')}}},[_vm._v(" Refund ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._l((item.challenges),function(challenge){return _c('dl',{key:challenge.toString()},[_c('dt',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(challenge.question))]),_c('dd',{staticStyle:{"font-style":"italic"}},[_vm._v(_vm._s(challenge.answer))])])}),(item.challenges.length === 0)?_c('span',[_vm._v("—")]):_vm._e()],2)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog.isOpen),callback:function ($$v) {_vm.$set(_vm.dialog, "isOpen", $$v)},expression:"dialog.isOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to "+_vm._s(_vm.dialog.action)+" this order? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog.isOpen = false}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.performDialogAction}},[_vm._v("Yes")])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }